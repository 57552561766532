<template>
<div class='templatesList'>
	<!-- <ListTabs /> -->
	<div class='controls'><div>
			<SearchInput 
				v-model='localSearch'
				@input='fetchSearch'
				@submit='fetchSearchImmediate'
				:placeholder='$t("views.foldersManager.phSearchSets")'
			/>
		</div>
	</div>
	<div class='content'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			:getSelected='() => $store.state.manageTemplates.selectedIds'
			:setSelected='(items) => $store.dispatch("manageTemplates/addSelectedIds", items.map(i => i ? i.id : null).filter(v => v))'
			:setSelecting='(items) => selectingIds = items.map(i => i ? i.id : null).filter(v => v)'			
			class='recycleScroller'
		>
			<template slot='before'><div @click='$store.dispatch("manageTemplates/setSelectedIds", [])'>
				<div class='pathWrapper'>
					<div v-if="folderId && folderId !== 'drafts'" class='fullPath bubble'>
						<div class="content fullPath">
							<span 
								v-for='(node, index) in pathNodes(folderId)' 
								:key='index'
								@click='$store.dispatch("manageTemplates/setFolderId", node.id)'
							>/&nbsp;&nbsp;<span>{{node.title}}</span>&nbsp;&nbsp;
							</span>	
							<!--<span v-html="pathTo(folderId, true)" />-->
						</div>
						<CloseButton @click='$store.dispatch("manageTemplates/setFolderId", null)' />
					</div>
					<div v-else class='fullPath bubble'>
						<div class="content">
							<span>Results from all folders...</span>
						</div>
					</div>
					<ResultInfo :total='total' :searched="searched" />	
				</div>			
				<div class='buttonWrapper'>
					<EnabledButton 
						v-if='canCreate'
						@click='createSet'
						:svg='"#plus"' :label='$t("elements.buttons.exerciseSet")' 
						class='createSet neutral tagPrimary' 
					/>		
					<div 
						v-if='$store.state.manageTemplates.folderId === "drafts" && total' 
						@click='deleteDrafts'
						class='deleteAllButton iconTextButton neutral tagAlert'
					>
						<svg class='smallIcon'>
							<use xlink:href='#delete' />
						</svg>
						<span class='label'>{{$t('elements.buttons.deleteAllDrafts')}} <b>&nbsp;({{total}})</b></span>
					</div>	
				</div>

				<div v-if='subfolders' class='subfolders'>
					<div 
						v-for='(folder, index) in subfolders' 
						:key='index'
						@click='$store.dispatch("manageTemplates/setFolderId", folder.id)'
						class='folder'
					>
						<svg class='smallIcon'>
							<use xlink:href='#folders' />
						</svg>		
						<span class='label'>{{folder.title}}</span>
					</div>
				</div>
			</div></template>		
			<template slot='sticky'>
				<TableColumns v-if='items.length'
					module='manageTemplates'
					:columns='[{
						label: $t("elements.cols.exerciseSet"),
						selectable: true,
						sort: "savename",
						flex: 1
					},{
						label: $t("elements.cols.lastUpdated"),
						selectable: true,
						sort: "updated",
						flex: 0
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem 
					:item='item' 
					:itemKey='item.id' 
					:path='pathTo(item.folderId)' 
					:canMove='canMove(item.folderId)' 
					:selectingIds='selectingIds' 
					@loadBus='loadBus'
					@rename='rename'
					@showContextMenu='showContextMenu'
					@loading='working=true'
				/>
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>	
	<ContextMenu 
		v-if='contextMenu' 
		:e='contextMenu'
		@close='contextMenu=false'
	>
		<div 
			class='iconTextButton neutral'
			@click='load'
		>
			<svg class='smallIcon'>
				<use xlink:href='#designer' />
			</svg>
			<span class='label'>Load in Designer ({{$store.state.manageTemplates.selectedIds.length}})</span>
		</div>
		<div 
			class='iconTextButton neutral'
			:class='{ disabled: !canRenameSelected }'
			@click='rename'
		>
			<svg class='smallIcon'>
				<use xlink:href='#edit' />
			</svg>
			<span class='label'>Rename <template v-if='typeof canRenameSelected==="string"'>&quot;{{canRenameSelected}}&quot;</template></span>
		</div>		
		<!--
		<div 
			class='iconTextButton neutral'
			:class='{ disabled: !canDeleteSelected }'			
			@click='$emit("deleteItems", $store.state.manageTemplates.selectedIds.map(id => ({ id, title: items.find(i=>i.id===id).savename })))'
		>
			<svg class='smallIcon'>
				<use xlink:href='#delete' />
			</svg>
			<span class='label'>Delete ({{$store.state.manageTemplates.selectedIds.length}})</span>
		</div>
		-->		
	</ContextMenu>			
</div>
</template>

<script>
import { debounce, isEqual, uniq } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { setsApi } from '@/services/api/modules/clinician/sets'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import TableColumns from '@/components/common/TableColumns'
import AtBottom from '@/components/common/AtBottom'
import SearchInput from '@/components/common/SearchInput'
import EnabledButton from '@/components/common/buttons/Enabled'
import CloseButton from '@/components/common/buttons/Close'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import TextInputModal from '@/components/common/modals/TextInput'
import Loading from '@/components/common/Loading'
import ContextMenu from '@/components/common/ContextMenu'
// import ListTabs from './ListTabs'
import ListItem from './ListItem'

export default {
	name: 'TemplatesList',
	components: { /* ListTabs, */ Loading, SearchInput, ContextMenu, CloseButton, RecycleScroller, TableColumns, AtBottom, ListItem, EnabledButton, ResultInfo },
	mixins: [smallModalMixin],
	props: ['folders'],
	data() { return {
		localSearch: '',
		working: false,
		total: 0,
		selectingIds: [],
		items: [],
		item: null,
		inDesigner: null,
		contextMenu: false,
		fetchSearch: debounce(function(search) {
			this.$store.dispatch('manageTemplates/setSearch', search)
				.then(() => this.fetchTop())
		}.bind(this), 1000, { leading: false, trailing: true })
	}},
	computed: {
		canCreate() {
			const folderId = this.$store.state.manageTemplates.folderId
			const type = folderId === 'drafts' ? 'drafts' : 
				folderId && this.folders && this.folders[folderId] ? this.folders[folderId].type : 'all'
			return (
				type === 'all' ||
				type === 'user' ||
				(type === 'org' && this.$store.state.profile.user.orgTemplates === 'readWrite') ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamTemplates === 'readWrite')
			)
		},
		canDeleteSelected() {
			if (this.$store.state.manageTemplates.folderId === 'drafts') return true
			const selectedIds = this.$store.state.manageTemplates.selectedIds
			return this.items
				.filter(i=>selectedIds.includes(i.id))
				.every(i=>this.canMove(i.folderId))
		},
		canRenameSelected() {
			const selectedIds = this.$store.state.manageTemplates.selectedIds
			if (selectedIds.length!==1) return false 
			const item = this.items.find(i=>i.id===selectedIds[0])
			if (!this.canMove(item.folderId)) return false 
			else return item.savename || true
		},		
		folderId() { return this.$store.state.manageTemplates.folderId },
		search() { return this.$store.state.manageTemplates.search },
		searched() { return this.$store.state.manageTemplates.searched },
		sort() { 
			return {
				col: this.$store.state.manageTemplates.sortColumn,
				dir: this.$store.state.manageTemplates.sortDirection
			}
		},
		filter() {
			return {
				folderId: this.folderId,
				search: this.search,
				sort: this.sort.col,
				sortDir: this.sort.dir,
			}
		},
		subfolders() {
			if (this.$store.state.manageTemplates.folderId) {
				return Object.values(this.folders).filter(f=>f && f.parentId === this.$store.state.manageTemplates.folderId).sort((a,b)=>a.index-b.index)
			} else if (!this.$store.state.manageTemplates.searched) {
				return [
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='user'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='team'),
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='org'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='link'),
				].filter(v=>v)
			} else {
				return null
			}
		}
	},
	methods: {
		showContextMenu(e, item, inDesigner) {
			this.item = item 
			this.inDesigner = inDesigner
			this.contextMenu = e
		},
		canMove(folderId) {
			if (this.$store.state.manageTemplates.folderId === 'drafts') return false
			const type = this.folders[folderId] ? this.folders[folderId].type : null
			return (
				type === 'user' ||
				type === 'org' && this.$store.state.profile.user.orgTemplates === 'readWrite' ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamTemplates === 'readWrite')
			) 
		},		
		fetchSearchImmediate() {
			this.fetchSearch.flush()
		},
		fetchTop() {
			this.working = true
			if (this.$store.state.manageTemplates.folderId === 'drafts') {
				const { search, sort, sortDir } = this.filter
				return setsApi.touch('fetchDrafts', { search, sort, sortDir }).then(([err, result]) => {
					if (!err) {
						this.items = result.items
						this.total = result.total
						this.$store.dispatch('manageTemplates/setSearched', this.filter.search)
					}
				})
				.finally(() => this.working = false)
			} else {
				return setsApi.touch('fetchTemplates', this.filter).then(([err, result]) => {
					if (!err) {
						this.items = result.items
						this.total = result.total
						this.$store.dispatch('manageTemplates/setSearched', this.filter.search)						
					}
				})
				.finally(() => this.working = false)
			}
		},
		fetchNext() {
			if (this.items.length < this.total) {
				this.working = true
				if (this.$store.state.manageTemplates.tab === 'drafts') {
					const { search, sort, sortDir } = this.filter
					return setsApi.touch('fetchDrafts', { skip: this.items.length, search, sort, sortDir }).then(([err, result]) => {
						if (!err) this.items.push(...result.items)
					})
					.finally(() => this.working = false)				
				} else {
					return setsApi.touch('fetchTemplates', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
						if (!err) this.items.push(...result.items)
					})
					.finally(() => this.working = false)
				}
			}
		},
		createSet() {
			const fn = (value) => {
				this.working = true
				const userRootId = Object.values(this.folders).find(f=>f.type==='user'&&!f.parentId).id
				const folderId = this.canCreate ? this.folderId || userRootId : userRootId
				setsApi
					.touch('createSet', { savename: value, type: 'folderSet', folderId })
					.then(([err, result])=> {
						//console.log(err, result)
						if (!err) return this.$store.dispatch('setBuilder/fetch', result.id)
							.then(() => {
								if (!this.canCreate) {
									this.$store.dispatch('flash/showAction', 'copyPersonal')
								}								
								this.$router.replace({ name: 'designer' })
							})
					})
					.finally(()=>this.working=false)
			}
			this.showSmallModal(TextInputModal, { title: this.$t('views.createFolderSet.title'), message: this.$t('views.createFolderSet.p1'), button: this.$t('elements.buttons.save'), fn })
		},
		moveSets(setIds, folderId) {
			this.working = true
			setsApi.touch('move', { ids: setIds.join(','), folderId })
				.then(([err]) => {
					if (!err) {
						return this.fetchTop().then(()=>{
							this.$store.dispatch('flash/showAction', 'moved')
							this.$store.dispatch('manageTemplates/setSelectedIds', [])					
						})
					}
				})
				.finally(() => this.working = false)	
		},
		pathNodes(folderId) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift({ title: node.title, id: node.id })
				if (node.parentId) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return nodes
		},
		pathTo(folderId, fullPath) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (
					node.parentId && 
					(fullPath || node.id !== this.$store.state.manageTemplates.folderId)
				) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return nodes.length ? "/ " + nodes.join("&nbsp;/ ")	: ""
		},
		getSelected() {
			return this.$store.state.manageTemplates.selectedIds
		},
		setSelected(keys) {
			this.$store.dispatch('manageFolderSets/setSelected', keys)
		},
		setSelecting(keys) {
			this.$store.dispatch('manageFolderSets/setSelecting', keys)
		},
		deleteDrafts() {
			const fn = () => {
				this.working = true
				setsApi.touch('destroyDrafts').then(this.fetchTop)
			}
			this.showSmallModal(ConfirmDeleteModal, { items: `${this.$t('views.foldersManager.lblAllDrafts')} (${this.total})`, fn })
		},
		loadBus(id) {
			const ids = uniq([id, ...this.$store.state.manageTemplates.selectedIds])
			const data = []
			ids.forEach(id => {
				const item = this.items.find(item => item.id === id)
				if (item) data.push({ id: item.id, title: item.savename || item.title || 'Untitled' })
			})
			if (data.length) this.$store.dispatch('bus/loadBus', {
				data,
				contentType: 'list'
			})
		},
		rename() {
			const item = this.items.find(i=>i.id===this.$store.state.manageTemplates.selectedIds[0])
			const fn = savename => {
				this.working = true
				setsApi.touch('updateSet', { id: item.id, savename })
					.then(this.fetchTop)
					.then(() => {
						const key = this.$store.getters['sets/getKeyById'](item.id)
						if (this.$store.state.sets.root[key]) this.$store.dispatch('sets/setSavename', { key, value: savename })
					})
			}
			this.showSmallModal(TextInputModal, {
				title: this.$t('views.editSetTitle.title'),
				placeholder: this.$t('views.editSetTitle.phSetTitle'),
				value: item.savename || item.title,
				button: this.$t('elements.buttons.saveAs'),
				maxlength: 100,
				fn
			})			
		},
		load() {
			this.working = true
			this.$store.dispatch('setBuilder/fetch', this.$store.state.manageTemplates.selectedIds)
				.then(()=>this.$router.replace({ name: 'designer' }))
		}
	},
	watch: {
		folderId(a,b) { if (a!==b) this.fetchTop() },
		sort(a,b) { if (!isEqual(a, b)) this.fetchTop() }
	},
	mounted() {
		this.localSearch = this.search
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.templatesList {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background:  $color-neutral-panel;

	.controls {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		margin-left: 0;

		> div {
			border: 1px solid $color-neutral-shadow;
			background: $color-neutral-shadow;
		}
	}

	.content { 
		position: relative; 
		.pathWrapper { 
			display: flex; 
			> div:first-child { margin-right: auto; }
		}
		.fullPath { 
			font-weight: bold; color: $color-primary-accent; background: none; border: none; 
			> span > span {
				cursor: pointer;
				&:hover { text-decoration: underline; }
			}
			.closeButton { margin-right: auto; margin-left: 0; }
		}		
		.buttonWrapper {
			display: flex;
			margin-bottom: $size-gutter * 2;
			margin-top: $size-gutter;
			> div {
				height: $size-control-height + 2px;
				background: none;
				&:last-child { margin-left: auto; }
				&:only-child { margin-right: auto; margin-left: 0; }
			}
		}
		.tableColumns {
			margin: $size-gutter * 2;
			margin-left: 0;
			margin-bottom: 0;
			> div:last-child { min-width: 179px;}
		}
		.resultInfo {
			margin-top: 1px;
			padding-left: $size-gutter * 2;		
		}
	}

	.subfolders {
		flex-basis: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		min-height: $size-control-height;
		padding: $size-gutter 0;

		.folder {
			margin: 3.5px $size-gutter;
			padding: 0; 
			font-size: $size-font-small;
			align-items: center;
			display: flex;
			padding-left: $size-gutter;
			cursor: pointer;
			padding-bottom: 1px;
			color: $color-primary-accent;
			font-weight: bold;
			svg { margin-right: $size-gutter * 1.5; color: $color-neutral-dark; transform: scale(0.9);}
			&:hover { 
				text-decoration: underline;
				svg { color: $color-primary-accent; }
			}
		}			
	}	

	.vue-recycle-scroller__item-wrapper:after {
		left: 0 !important;
		width: calc(100% - #{$size-gutter * 2}) !important;
	}	
}
</style>