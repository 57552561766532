<template>
<div 
	class='templateListItem ctrlLasso' 
	:class='{ selected, selecting }'
	@click='onClick'
	@mousedown='onMouseDown'
>
	<div class='title'>	
		<div class='selectItem' @click.stop='$store.dispatch("manageTemplates/toggleSelectedId", item.id)'>	
			<CheckButton :checked='selected' />
		</div>
		<!--
		<div class='itemMenu' @click.stop='showContextMenu'>
			<svg class='menuIcon smallIcon'>
				<use xlink:href='#menu' />
			</svg>
		</div>
	-->
		<span class='overflow savename'>
			<template v-if='item.savename && item.title'>{{item.savename}} ({{item.title}})</template>
			<template v-else>{{item.savename || item.title || $t("elements.labels.untitled")}}</template>
		</span>

		<div v-if='item.previews && item.previews.length' class='previews'>
			<div class='img' v-for='(url, index) in item.previews' :key='index'>
				<img :src='url' loading='lazy' />
			</div>
		</div>
		<!--
		<div 
			class='renameButton miniButton neutral' 
			@mousedown.stop @click.stop='$emit("rename", item, inDesigner)'
		>{{ $t('elements.buttons.rename') }}</div>		
	-->
		<svg v-if='!canMove && $store.state.manageTemplates.folderId !== "drafts"' class='readOnly miniIcon typeIcon'>
			<use xlink:href='#lock' />
		</svg>		
		<span class='overflow path' v-html='path || item.clientName' />
		<div 
			v-if='inDesigner'
			class='inDesigner tag primary' 
			@mousedown.stop @click.stop='onDblClick'
		>{{ $t('elements.labels.inDesigner') }}</div>
		<div 
			v-else-if='item.draftId'
			class='restoreDraftButton tag success' 
			@mousedown.stop @click.stop='loadDraft'
		>Restore Draft</div>	
		<!--
		<div 
			v-if='!inDesigner'
			class='openButton miniButton neutral' 
			@mousedown.stop @click.stop='onDblClick'
		>{{ $t('elements.buttons.load') }}</div>	
		-->			
	</div>
	<div class='updatedCol'><div>
		<div class='updated'>{{updated}}</div>
		<div v-if='item.savedBy' class='savedBy'>{{$t('elements.parts.by')}} {{item.savedBy}}</div>
	</div></div>	

</div>
</template>

<script>
import { Distance } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'TemplateListItem',
	mixins: [dateTime, smallModalMixin],
	components: { CheckButton },
	props: ['item','path','canMove','selectingIds'],
	computed: {
		updated() {
			this.langBackdoor
			return this.dateTime(this.item.updated) 			
		},
		selected() {
			return this.$store.state.manageTemplates.selectedIds.includes(this.item.id)
		},
		selecting() {
			return this.selectingIds.includes(this.item.id)
		},		
		inDesigner() {
			return !!this.$store.getters['sets/getKeyById'](this.item.id)
		}
	},
	methods: {
		showContextMenu(e, item, inDesigner) {
			if (!this.selected) this.$store.dispatch('manageTemplates/setSelectedIds', [this.item.id])
			this.$emit('showContextMenu', e)
		},
		onMouseDown(e) {
			if (e.ctrlKey || e.metaKey || e.shiftKey) return
			if (e.which && e.which === 3) this.showContextMenu(e)				
			else if (this.canMove && !this.$store.state.main.mobile) new Distance({
				onPull: async () => {
					if (!this.selected) await this.$store.dispatch('manageTemplates/setSelectedIds', [this.item.id])
					this.$emit('loadBus', this.item.id)
				},
				e
			})
		},	
		onDblClick() {
			this.load(this.item.id)
		},
		onClick(e) {
			if (this.$store.state.bus.bussing) {
				e.stopImmediatePropagation()
				return false		
			}
			if (e.ctrlKey || e.metaKey || e.shiftKey) this.$store.dispatch('manageTemplates/toggleSelectedId', this.item.id)
			else this.load(this.item.id) //this.$store.dispatch('manageTemplates/setSelectedIds', [this.item.id])
		},		
		select() {
			//this.$router.push({ params: { educationId: this.item.id }}).catch(()=>{})
		},
		loadDraft() {
			this.load(this.$store.getters['sets/getIds'].includes(this.item.id) ? this.item.id : this.item.draftId)
		},
		load(id) {
			this.$emit('loading')
			this.$store.dispatch('setBuilder/fetch', id)
				.then(()=>this.$router.replace({ name: 'designer' }))
		}
	}
}
</script>

<style lang='scss'>
.templateListItem {
	background: $color-white;
	display: flex;
	align-items: center;
	height: $size-control-height;
	margin-right: $size-gutter * 2;

	&:hover { background: $color-neutral-silver; }
	&.selected { background: $color-focus !important; .checkButton svg { color: $color-black; }}
	&.selecting { 
		background:  $color-focus !important;
		/*.checkButton > svg { color: $color-primary-accent; } */
	}

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		&:not(.delete) {
			cursor: pointer;
		}

		> div > div { font-size: $size-font-small; color: $color-primary-accent; }
	}

	.updated {
		flex: .4;
	}

	.title {
		flex: 1;
		padding-right: 0;
		padding-left: 0;
		.selectItem { 
			height: 40px;	
			padding-left: $size-gutter * 2;	
			padding-right: $size-gutter * 2;	
			display: flex;
			align-items: center;
		}
		.checkButton { color: $color-neutral-dark; pointer-events: none; }		
		.itemMenu { 
			padding-right: $size-gutter * 2;
			padding-left: $size-gutter;
			height: 40px;
			display: flex;
			align-items: center;
			svg { color: $color-neutral-dark; }
		}		
		.path { color: $color-neutral-dark; font-size: $size-font-small; }
		.tag { margin-right: $size-gutter * 2 }
		> span { white-space: nowrap; }
		.inDesigner {
			margin-left: auto;
			white-space: nowrap;
		}		
		.renameButton { margin-left: $size-gutter * 2; margin-right: $size-gutter; }
		.restoreDraftButton {
			margin-left: auto;
			cursor: pointer;
			white-space: nowrap;
		}		
		.openButton { margin-left: auto; }		
		.restoreDraftButton ~ .openButton {
			margin-left: 0;
		}
		.savename { margin-right: $size-gutter * 2; }
		.typeIcon {
			color: $color-neutral-dark; margin-right: $size-gutter; 
		}

		.previews {
			display: flex;
			pointer-events: none;
			&:not(:empty) { padding-right: $size-gutter * 2; }
			.img {
				position: relative;
				width: 30px; height: 30px; 
				overflow: hidden;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	.checkCol {
		flex: 0;
		flex-basis: 45px;
		> div { pointer-events: none;}
	}

	.updatedCol {
		flex: 0;
		flex-basis: 180px;
		.savedBy { color: $color-neutral-dark; margin-top: 1px;}
		.updated { color: $color-black;  }
	}

	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }



}
</style>