<template>
<div class='templatesManager' :style='style'>
	<Folders @update='onFolderUpdate' @drop='onFolderDrop' />
	<List 
		ref='list' 
		:folders='folders' 
		@deleteItems='deleteItems'		
	/>
	<Preview 
		v-show='$store.state.manageTemplates.selectedIds.length' 
		:ids='$store.state.manageTemplates.selectedIds' 
		:folders='folders'
		@unselect='(id)=>$store.dispatch("manageTemplates/toggleSelectedId", id)' 
		@unselectAll='$store.dispatch("manageTemplates/setSelectedIds", [])' 
		@deleteItems='deleteItems'
	/>
</div>
</template>

<script> 
import { smallModalMixin } from '@/components/common/mixins/modal'
import { setsApi } from '@/services/api/modules/clinician/sets'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import Folders from './Folders'
import List from './List'
import Preview from './Preview'

export default {
	name: 'TemplatesManager',
	components: { Folders, List, Preview },
	mixins: [smallModalMixin],
	data() { return {
		folders: {}
	}},
	computed: {
		style() {
			return this.$store.state.manageTemplates.selectedIds.length ? 'grid-template-columns: minmax(230px, 20%) 1fr minmax(320px, 20%);' : 'grid-template-columns: minmax(230px, 20%) 1fr;'
		}
	},
	methods: {
		onFolderUpdate(val) {
			this.folders = val
		},
		onFolderDrop(val) {
			if (val.folderId !== val.data[0].folderId)
				this.$refs.list.moveSets(val.data.map(i => i.id), val.folderId)
		},
		deleteItems(items) {
			const fn = () => {
				this.$refs.list.working = true			
				setsApi.touch('destroy', { ids: this.$store.state.manageTemplates.selectedIds.join(',') })
					.then(([err]) => {
						if (!err) {
							this.$store.dispatch('manageTemplates/setSelectedIds', [])
							return this.$refs.list.fetchTop()
						}
					})
					.finally(() => this.$refs.list.working = false)	
			}
			this.showSmallModal(ConfirmDeleteModal, { items: items.map(i => i.title), fn })
		}
	}
}
</script>

<style lang='scss'>
.templatesManager {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: minmax(230px, 20%) 1fr;
	background: repeating-linear-gradient(
		180deg,
		darken($color-neutral, 5%),
		darken($color-neutral, 5%) 14px,
		$color-neutral-panel 14px,
		$color-neutral-panel 28px
	);
	background-position: 0 -14px;	
}
</style>