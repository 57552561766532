<template>
	<div class='templatesPreview' :style='style'>
		<div class='header' v-if='ids && ids.length <= 10'>
			<div class='iconTextButton silver' @click='load'>
				<svg class='smallIcon'>
					<use xlink:href='#designer' />
				</svg>
				<span class='label'>{{$t("elements.buttons.loadInDesigner")}} &nbsp;<b>({{ids.length}})</b></span>
			</div>
		</div>
		<div class='content'>
			<Scroll>
				<div class='total'>
					<b>{{$t("elements.labels.selected")}}:</b>&nbsp;{{ids.length}}
					<div 
						v-if='ids && ids.length'
						class='miniButton silver' 
						@click.stop='$emit("unselectAll")'
					>{{ $t('elements.buttons.unselectAll') }}</div>
				</div>
				<template v-if='ids && ids.length <= 10'>
					<div v-for='preview in previews' :key='preview.id' class='preview'>
						<div class='setTitle'>
							{{preview.title}}
							<svg v-if='preview.education' class='education smallIcon'>
								<use xlink:href='#attach' />
							</svg>
							<CloseButton @click='$emit("unselect", preview.id)' />
						</div>
						<div 
							v-for='(element, i) in preview.elements'
							:key='i'
							:class='{ divider: element.type === "setDivider" }'
							class='element bubble'
						>
							<LazyImage v-if='element.thumbnail' :src='element.thumbnail' class='thumbnail' :contain='true' />
							<div class='content'><div>
								<div class='elementTitle'>{{element.title}}</div>
								<div class='parameters'>
									<span
										v-for='(parameter, index) in element.parameters'
										:key='index'
										class='parameter'
									><b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' /></span>
								</div>
							</div></div>
						</div>
					</div>	
				</template>
				<div 
					v-else
					class='previewTitles'
				>
					<span
						v-for='preview in previews'
						:key='preview.id'
					>{{preview.title}}<br /></span>
				</div>				
			</Scroll>
		</div>
		<div v-if='previews.length && canDelete' class='footer'>
			<div class='iconTextButton tagAlert' @click='$emit("deleteItems", previews.map(p => ({ id: p.id, title: p.title })))'>
				<svg class='smallIcon'>
					<use xlink:href='#delete' />
				</svg>
				<span class='label'>{{$t("elements.buttons.delete")}} &nbsp;<b>({{previews.length}})</b></span>
			</div>
		</div>				
		<transition name='fade'><Loading v-show='working' /></transition>
	</div>
</template>

<script>
import { setsApi } from '@/services/api/modules/clinician/sets'
import LazyImage from '@/components/common/LazyImage'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'
import CloseButton from '@/components/common/buttons/Close'

export default {
	name: 'TemplatesPreview',
	components: {Loading, Scroll, LazyImage, CloseButton},
	props: ['ids','folders'],
	data() { return {
		sets: {},
		resources: {},
		working: false
	}},
	computed: {
		previews() {
			const loadedIds = Object.keys(this.sets)
			const ids = this.ids ? this.ids.filter(id => loadedIds.indexOf(id+'') > -1) : []
			return ids.map(id => ({
				id,
				title: this.sets[id].savename || this.sets[id].title || 'Untitled',
				education: !!this.sets[id].education.length,
				elements: this.sets[id].elements.map(e => ({
					type: e.type,
					title: e.title,
					thumbnail: e.thumbnailId && this.resources[e.thumbnailId] ? this.resources[e.thumbnailId].url : 
						e.resources && e.resources.length && this.resources[e.resources[0].resourceId] ? this.resources[e.resources[0].resourceId].url : 
						null,
					parameters: e.parameters ? e.parameters.filter(p=>p.value).map(p => ({
						title: p.title,
						value: p.value
					})) : null
				}))
			}))
		},
		style() {
			return this.previews.length <= 10 ? 'grid-template-rows: auto 1fr;' : 'grid-template-rows: 1fr;'
		},
		canDelete() {
			return this.ids.every(id =>
				this.sets[id] &&
				(
					this.sets[id].type === 'draftSet' ||
					this.folders[this.sets[id].folderId] && (
						this.folders[this.sets[id].folderId].type === 'user' ||
						(this.folders[this.sets[id].folderId].type === 'team' && this.$store.state.profile.teams[this.folders[this.sets[id].folderId].teamId].teamTemplates === 'readWrite') ||
						(this.folders[this.sets[id].folderId].type === 'org' && this.$store.state.profile.user.orgTemplates === 'readWrite')		
					)			
				)
			)
		}
	},
	methods: {
		load() {
			this.working = true
			this.$store.dispatch('setBuilder/fetch', this.ids)
				.then(()=>this.$router.replace({ name: 'designer' }))			
		},
		fetch(ids) {
			this.working = true
			setsApi.touch('fetchIds', { ids: ids.join(',') })
				.then(([err, result]) => {
					if (!err) {
						const { items, resources } = result
						items.forEach(item => this.$set(this.sets, item.id, item))
						resources.forEach(resource => this.resources[resource.id] = resource)
					}
				})
				.finally(() => this.working = false)
		}
	},
	watch: {
		ids(value) {
			const loadedIds = Object.keys(this.sets)
			const diff = value.filter(id => loadedIds.indexOf(id+'') === -1)
			if (diff.length) this.fetch(diff)
		}
	},
	mounted() {
		if (this.ids && this.ids.length) this.fetch(this.ids)
	}
}
</script>

<style lang="scss">
.templatesPreview {
	background:  $color-neutral-panel;
	position: relative;
	display: grid;
	margin-left: 1px; 
	> div { position: relative; }
	> .header > div {
		margin: $size-gutter * 2;
		margin-bottom:  $size-gutter * 2 + 1;
		height: $size-control-height * 2 + 3;
		border: 1px solid $color-neutral-shadow;		
	}
	> .content {
		.total {
			position: relative;
			top: 1px;
			display: flex;
			align-items: center;
			line-height: $size-control-height;
			padding-left: $size-gutter * 4;
			font-size: $size-font-standard;
			padding-top: $size-gutter * 2;
			.miniButton {
				cursor: pointer;
				margin-left: $size-gutter * 2;
				border: 1px solid $color-neutral-shadow;
			}
		}

		.previewTitles {
			margin-top: $size-gutter * 2;
			margin-left: $size-gutter * 4;
			margin-right: $size-gutter * 2;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			margin-bottom: $size-gutter * 2;
		}

		.preview {
			margin-left: $size-gutter * 2;
			margin-right: $size-gutter * 2;
			padding-top: 0;
			border: 1px solid $color-neutral-shadow;
			padding: $size-gutter;
			background: #fff;
			&:not(:last-child) { margin-bottom: $size-gutter * 2; }
		}

		.setTitle {
			display: flex;
			align-items: center;
			height: $size-control-height;
			font-size: $size-font-standard;
			font-weight: bold;
			padding-left: $size-gutter * 2;
			background: $color-neutral-accent;
			.education { margin-left: $size-gutter * 2; }
			.closeButton {
				margin-left: auto;
			}
		}

		.element {
			overflow: hidden;
			background: $color-white;
			&.divider {
				background: $color-neutral-accent;
			}
		}

		.content {

			flex: 1;
			overflow: hidden;
			display: flex;
			align-items: center;

			> div {
				overflow: hidden;
			}
		}		

		.thumbnail {
			width: 50px;
			height: 50px;
		}

		.elementTitle {
			font-size: $size-font-small;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.parameters {
			font-size: $size-font-small;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.parameter {
				margin-right: $size-gutter;
				padding-left: $size-gutter;
				border-left: 1px solid $color-neutral;

				&:first-child {
					padding-left: 0;
					border-left: none;
				}
			}
		}
	}

}
</style>
