<template>
<div class='templateFolders'>
	<transition name='fade'>
		<Scroll v-show='loaded'>
			<DynamicTree 
				:data='userTree' 
				:editable='true' 
				:selected='selected'		
				icon='personal' 
				rootTitle='Personal'
				@select='onSelect'
				@drop='onDrop'
			/>
			<DynamicTree 
				v-for='(teamTree, index) in teamTrees'
				:key='index'
				:data='parseTeamTreeValue(teamTree)' 
				:editable='isTeamTreeEditable(teamTree)' 
				:selected='selected'		
				icon='team' 
				rootTitle='Team'				
				@select='onSelect'
				@drop='onDrop'			
			/>
			<DynamicTree 
				v-if='orgTree' 
				:data='orgTree' 
				:editable='$store.state.profile.user.orgTemplates === "readWrite"' 		
				:selected='selected'		
				icon='organization' 
				rootTitle='Organization'				
				@select='onSelect'
				@drop='onDrop'			
			/>
			<DynamicTree 
				v-for='(linkTree, index) in linkTrees'
				:key='`link-${index}`'
				:data='linkTree' 
				:editable='false' 
				:selected='selected'		
				icon='link' 
				:rootTitle='`Link — ${linkTree.link}`'				
				@select='onSelect'
			/>	
			<div 
				class='drafts iconTextButton'
				:class='{ selected: $store.state.manageTemplates.folderId === "drafts" }' 
				@click='$store.dispatch("manageTemplates/setFolderId", $store.state.manageTemplates.folderId !== "drafts" ? "drafts" : null)'
			>
				<svg class='miniIcon'>
					<use xlink:href='#drafts' />
				</svg>
				<span class='label'>{{$t("elements.buttons.fldrDrafts")}}</span>			
			</div>		
		</Scroll>
	</transition>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { walkTreeData } from 'he-tree-vue'
import { replaceKeysDeep, arrToKeyedObj } from '@/utilities'
import { foldersApi } from '@/services/api/modules/clinician/folders'
import DynamicTree from '@/components/common/DynamicTree'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'

export default {
	name: 'TemplateFolders',
	components: { DynamicTree, Loading, Scroll },
	data() { return {
		loaded: false,
		loading: false,
		userTree: {},
		teamTrees: [],
		orgTree: {},
		linkTrees: []
	}},
	computed: {
		flattenedTree() {
			const flatten = tree => {
				const arr = []
				const fn = (node, parentId, index = 0) => {
					arr.push({ id: node.id, parentId, title: node.title, index })
					if (node.children) node.children.forEach((child, index) => fn(child, node.id, index))					
				}
				fn(tree)
				return arr
			}
			const flattened = flatten(this.userTree).map(node=>({ type: 'user', ...node }))
			if (this.orgTree) flattened.push(...flatten(this.orgTree).map(node=>({ type: 'org', ...node })))
			if (this.teamTrees) this.teamTrees.forEach(teamTree=>{
				const [teamId, tree] = Object.entries(teamTree)[0]
				flattened.push(...flatten(tree).map(node=>({ type: 'team', teamId, ...node })))
			})
			if (this.linkTrees) this.linkTrees.forEach(linkTree=>{
				flattened.push(...flatten(linkTree).map(node=>({ type: 'link', ...node })))
			})
			return arrToKeyedObj(flattened)
		},
		selected() {
			return this.$store.state.manageTemplates.folderId
		}
	},
	methods: {
		parseTeamTreeValue(teamTree) {
			return Object.values(teamTree)[0]
		},
		isTeamTreeEditable(teamTree) {
			return this.$store.state.profile.teams[Object.keys(teamTree)[0]].teamTemplates === "readWrite"
		},
		fetch() {
			this.loading = true
			foldersApi.touch('fetchTemplateFolders').then(([err, result]) => {
				if (!err) {
					const { folders } = replaceKeysDeep(result, { subordinates: "children" })
					this.userTree = folders.user 
					this.teamTrees = folders.teams 
					this.orgTree = folders.org
					this.linkTrees = folders.links
				}
				this.loading = false
				this.loaded = true
			})
		},
		onSelect(id) {
			this.$store.dispatch('manageTemplates/setFolderId', +id || null)
		},
		onDrop(value) {
			this.$emit('drop', value)
		}
	},
	watch: {
		selected() { this.$store.dispatch('manageTemplates/setSelectedIds', []) },
		flattenedTree(a) {
			this.$emit('update', a)
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.templateFolders {
	@include fill;
	position: relative;
	background:  $color-neutral-panel;
	.drafts { padding-left: 7px; justify-content: left; &.selected { color: $color-primary-accent; }}
	.scrollContent {
		padding-top: $size-gutter * 2;
		padding-left: $size-gutter * 2;
	}
}
</style>